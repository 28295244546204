import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, Select, Typography } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import timezoneList from "timezones-list";
import { useForm } from "antd/es/form/Form";
import Api from "../../utils/Axios";
import { useDispatch } from "react-redux";
import { updateUser } from "../../features/auth/authSlice";
import axios from "axios";
import Auth from "../../components/Middleware/Auth";
import AuthLayout from "../../layouts/AuthLayout";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function CreateOrganization() {
  const navigate = useNavigate();
  const [orgForm] = useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [timezoneLoading, setTimezoneLoading] = useState(false);
  const dispatch = useDispatch();

  const getUpdatedUser = useCallback(async () => {
    try {
      const { data } = await Api.get("/me");
      dispatch(updateUser(data.data));
    } catch (error) {
      console.error("Something went wrong");
    }
  }, [dispatch]);

  const handleFormSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true)
        await Api.Post("/organization", values);
        getUpdatedUser();
        navigate("/organization");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          const errors = error.response.data.errors;
          const validationErrors = Object.keys(errors).map((key) => ({
            name: key,
            errors: errors[key],
          }));
          orgForm.setFields(validationErrors);
        } else {
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate, getUpdatedUser, orgForm]
  );

  const timezones = useMemo(
    () =>
      timezoneList.map((item) => ({
        value: item.tzCode,
        label: item.label.replace('GMT', 'UTC'),
      })),
    []
  );

  const uploadURL = process.env.REACT_APP_BACKEND_URL + "/upload";

  const handlePreview = async (file) => {
    if (file && !file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    if (file) {
      setImageUrl(file.url || file.preview);
    }
  };

  const handleFileChange = useCallback(
    (info) => {
      if (info.file) {
        handlePreview(info.file);
        orgForm.setFieldValue("logo", info.file.response);
      }
    },
    [orgForm]
  );

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const getTimezone = useCallback(async () => {
    try {
      setTimezoneLoading(true);
      const { data } = await axios.get('https://ipapi.co/timezone/')
      orgForm.setFieldValue('timezone', data);
    } catch (error) {
      console.error(error)
    } finally {
      setTimezoneLoading(false);
    }
  }, [orgForm])

  useEffect(() => {
    getTimezone()
  }, [getTimezone])

  return (
    <Auth>
      <AuthLayout>
        <div className="mt-10 mb-5">
          <Typography.Title
            level={2}
            className="text-left text-dark font-semibold font-poppins"
          >
            Create your <span className="text-secondary">Workotick</span>{" "}
            organization
          </Typography.Title>
          <Typography.Paragraph className="font-poppins font-light">
            Ready to lead your team to new heights? Create a new organization to
            streamline collaboration, manage projects efficiently, and track time
            like a pro.
          </Typography.Paragraph>
        </div>
        <Form
          layout="vertical"
          className="mt-0 "
          onFinish={handleFormSubmit}
          form={orgForm}
          disabled={loading}
        >
          <div className="">
            <Typography.Paragraph className="font-poppins text-dark text-sm mb-1.5">
              Organization Name*
            </Typography.Paragraph>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Please enter organization name" },
              ]}
            >
              <Input
                className="w-full px-4 py-3 border rounded-md"
                placeholder="Organization Name"
              />
            </Form.Item>
          </div>
          <div className="">
            <Typography.Paragraph className="font-poppins text-dark text-sm mb-1.5">
              Timezone*
            </Typography.Paragraph>
            <Form.Item
              name="timezone"
              rules={[{ required: true, message: "Please select timezone" }]}
            >
              <Select
                placeholder="Select timezone"
                className="h-12"
                options={timezones}
                loading={timezoneLoading}
                disabled={timezoneLoading}
                showSearch
              />
            </Form.Item>
          </div>

          <div className="">
            <Typography.Paragraph className="font-poppins text-dark text-sm mb-5">
              Organization Logo
            </Typography.Paragraph>
            <Form.Item
              name="logo"
              rules={[{ required: false, message: "Please upload the logo" }]}
            >
              <Upload
                name="file"
                listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                action={uploadURL}
                onPreview={handlePreview}
                onChange={handleFileChange}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" className="rounded-full" />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </div>
          <Form.Item className="mt-8">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full h-12 bg-primary text-white font-poppins text-medium"
              loading={loading}
            >
              Create Organization
            </Button>
          </Form.Item>
        </Form>
        <div className="sf-new-org-hr relative mt-5">
          <Link to="/organization">
            <Typography.Paragraph className="font-poppins text-center mx-auto max-w-[200px] bg-white text-primary">
              Select Organization
            </Typography.Paragraph>
          </Link>
        </div>
      </AuthLayout>
    </Auth>
  );
}
